import React from 'react'
import PropTypes from 'prop-types'
import { isNotNullUndefinedOrEmpty, isNotUndefinedAndNull } from 'shared/util/helper'

const ShipVia = (props) => {
  return (
    <>
      {isNotUndefinedAndNull(props.shipVia)
        ? (
          <>
            {isNotNullUndefinedOrEmpty(props.shipVia) && (
              <>
                <span>{props.shipVia}</span>
                <br />
              </>
            )}
          </>
        )
        : (
          <div className='cat-sub-title'>---</div>
        )}
    </>
  )
}

ShipVia.propTypes = {
  shipVia: PropTypes.string
}

export default ShipVia
