import React, { createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import userService from 'shared/services/user-service'
import { ToastMessage } from 'components/uiElements'
import { useLocation, useNavigate } from 'react-router-dom'

export const UserContext = createContext()
export const AuthContextProvider = ({ children }) => {
  const getCurrentUser = userService.getCurrentUser()
  const [user, setUser] = React.useState(getCurrentUser || null)
  const [loading, setLoading] = React.useState(false)
  const [toast, setToast] = React.useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  const newParam = new URLSearchParams(location.search)

  const onChangeRouter = (key, value) => {
    const Param = new URLSearchParams(location.search)
    Param.set(key, value)
    if (!value) {
      Param.delete(key)
    }
    navigate({ search: Param.toString() })
  }
  const showToast = (message, isSuccess) => {
    setToast({ message, isSuccess })
    setTimeout(() => hideToast(), 3000)
  }
  const hideToast = () => {
    setToast(null)
  }

  const setCurrentUser = () => {
    setUser(userService.getCurrentUser())
  }

  const contextObj = {
    userData: user,
    setUserData: setCurrentUser,
    setUser,
    showToast,
    hideToast,
    loading,
    setLoading,
    onChangeRouter,
    location,
    navigate,
    newParam,
    toast: toast ? toast.message : null
  }
  useEffect(() => {
    const handleInputClick = (e) => {
      const input = e.target
      if (input && input.type === 'date') {
        input.showPicker()
      }
    }
    const observer = new MutationObserver(() => {
      const dateInputs = document.querySelectorAll('input[type="date"]')
      dateInputs.forEach((input) => {        
        if (!input.hasAttribute('data-click-listener')) {
          input.addEventListener('click', handleInputClick)
          input.setAttribute('data-click-listener', true)
        }
      })
    })
    observer.observe(document.body, { childList: true, subtree: true })
    const dateInputs = document.querySelectorAll('input[type="date"]')
    dateInputs.forEach((input) => {
      input.addEventListener('click', handleInputClick)
      input.setAttribute('data-click-listener', true)
    })
    return () => {
      observer.disconnect()
      const dateInputs = document.querySelectorAll('input[type="date"]')
      dateInputs.forEach((input) => {
        input.removeEventListener('click', handleInputClick)
        input.removeAttribute('data-click-listener')
      })
    }
  }, [])
  return (
    <UserContext.Provider value={contextObj}>
      {children}
      {toast && (
        <div style={{ marginTop: '10px', paddingTop: 20 }} className='position-fixed top-0 bottom- end-0 mt-4 p-5 '>
          <ToastMessage
            color={toast.isSuccess === true ? 'success' : toast.isSuccess === false ? 'warning' : 'danger'}
            message={toast.message}
          />
        </div>
      )}
    </UserContext.Provider>
  )
}
AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
