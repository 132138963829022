import { CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'

import React, { useContext, useState } from 'react'

import PropTypes from 'prop-types'
import { AiOutlinePlus } from 'react-icons/ai'
import { IconButton } from '@mui/material'
import SearchableDropdown from './SearchableDropdown'
import './Style.css'
import { UserContext } from 'context/AuthContext/UserContext'

function PoSku(props) {
  const [value, setValue] = useState({ poProduct: {}, qty: '', supplier: '', isNew: false })
  const [notSelected, setNotSelected] = useState(false)
  const [qtyError, setQtyError] = useState(false)
  const { userData } = useContext(UserContext)
  const preventNegativeValue = (e) => {
    if (e.target.value <= 0) {
      e.target.value = ''
    }
  }
  const filteredProducts = props.vendorsAllProducts.filter(
    (product) => !props.skuValue.some((skuItem) => skuItem.poProduct.id === product.id)
  )

  const handleAddProduct = () => {
    if (value?.poProduct?.images && value?.poProduct?.images?.length > 0 && value?.qty > 0) {
      setNotSelected(false)
      setQtyError(false)
      let arr = [...props.skuValue]

      value.createdBy = userData?.user?.pomUserType === 1 ? 'User' : 'Vendor'
      arr.push(value)
      props.setSkuValue(arr)

      const filteredArr = props.vendorsAllProducts.filter((product) => product.id !== value.poProduct.id)
      props.setVendorsAllProducts(filteredArr)

      setValue({ poProduct: '', qty: '', supplier: '' })
    } else {
      if (!(value?.poProduct?.images && value?.poProduct?.images?.length > 0)) {
        setNotSelected(true)
      }
      if (!(value.qty > 0)) {
        setQtyError(true)
      }
    }
  }
  return (
    <>
      <CRow className='mt-5 ms-4'>
        <h3>Add Products</h3>
        <hr />
      </CRow>
      <CForm onSubmit={handleAddProduct}>
        <CRow className='ms-4 mt-2'>
          <CCol sm={4}>
            <CFormLabel htmlFor='type' className='col-sm-4 col-form-label' data-testid='Supplier'>
              Product
            </CFormLabel>
            <SearchableDropdown
              options={filteredProducts}
              supplier={props.supplier}
              measurements='measurements'
              images='images'
              label='sku'
              id='id'
              selectedVal={value.poProduct}
              onHandleChange={(val) => {
                setValue({ ...value, poProduct: val, supplier: props.supplier, isNew: true }), setNotSelected(false)
              }}
            />
            {notSelected && <p style={{ color: 'red' }}> Please Select A Sku</p>}
          </CCol>

          <CCol sm={4}>
            <CFormLabel htmlFor='name' className='col-sm-4 col-form-label'>
              Quantity
            </CFormLabel>
            <CFormInput
              size='lg'
              type='number'
              id='Quantity'
              placeholder='Enter Qty'
              onChange={(e) => {
                setValue({ ...value, qty: e.target.value }), setQtyError(false)
              }}
              onInput={preventNegativeValue}
              value={value.qty}
            />
            {qtyError && <p style={{ color: 'red' }}> Please Enter Valid Qty</p>}
          </CCol>
          <CCol sm={4} className='mt-4'>
            <IconButton aria-label='view' color='success' className='mt-3 border rounded-0 ' onClick={handleAddProduct}>
              <AiOutlinePlus fontSize={26} color='black' />
            </IconButton>
          </CCol>
        </CRow>
      </CForm>
    </>
  )
}

export default PoSku

PoSku.propTypes = {
  handleChange: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  vendorProducts: PropTypes.array,
  validated: PropTypes.bool,
  values: PropTypes.object,
  skuValue: PropTypes.object,
  supplier: PropTypes.string.isRequired,
  setSkuValue: PropTypes.func,
  setVendorProducts: PropTypes.func,
  vendorsAllProducts: PropTypes.array,
  setVendorsAllProducts: PropTypes.func
}
