import React from 'react'
import { Grid, Button as MUIButton } from '@mui/material'
import PropTypes from 'prop-types'

import Button from 'components/formElements/Button'

function ProposalValidation(props) {
  const submitHandler = () => {
    const validateData = []
    props.rows.map((products) => {
      validateData.push({
        pId: products?.id,
        poId: props.PoId,
        qty: (products.proposedQty && products.proposedQty !== 0) ? products.proposedQty : products.expectedQty?products.expectedQty:products.validatedQty,
        sku: props.sku
      })
    })
    props.ValidationSubmit(validateData)

  }
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' className='mb-4'>
      <Grid item sm={10}>
        <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Are you sure ?</h5>
      </Grid>

      <Grid item sm={12} className='mt-3'>
        <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
          <Grid item>
            <MUIButton variant='outlined' onClick={props.cancelAction}>
              Cancel
            </MUIButton>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              //disabled={!formState.isValid}
              onClick={submitHandler}
              // isLoading={props.validating}
              color='success'
              label='Validate'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
ProposalValidation.propTypes = {
  ValidationSubmit: PropTypes.func,
  pId: PropTypes.string,
  PoId: PropTypes.string,
  validating: PropTypes.bool,
  cancelAction: PropTypes.func,
  rows: PropTypes.object,
  sku: PropTypes.string
}

export default ProposalValidation
