import { useMutation, useQuery } from 'react-query'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'context/AuthContext/UserContext'
import { sortAlphabeticOrder } from 'shared/util/helper'

export const useRefundHooks = () => {
  const [loading, setLoading] = useState(false)
  const contextObj = useContext(UserContext)
  const [shop, setShop] = useState([])
  const [errors, setErrors] = useState({})
  const [debounceVal, setDebounceValue] = useState('')
  const [warehouse, setWareHouse] = useState()

  const [refundDetails, setRefundDetails] = useState({
    marketplace_name: '',
    date_of_refund: '',
    order_date: '',
    order_id: '',
    return_fee: '',
    country_of_dispatch: '',
    shipped_country: '',
    transaction_type: '',
    customer_support: contextObj.userData.user?.id,
    refund_transaction_type: '',
    status: '',
    sku: ''
  })

  // Custom hook to implement a debounce effect
  const useDebounce = (value, delay) => {
    // Side effect that runs when `value` or `delay` changes.
    useEffect(() => {
      // Set up a timeout to update the debounced value after the specified delay.
      const handler = setTimeout(() => {
        setDebounceValue(value) // Update the debounced value after the delay.
      }, delay)

      // Cleanup function to clear the timeout if `value` or `delay` changes before the timeout finishes.
      return () => {
        clearTimeout(handler)
      }
    }, [value, delay]) // Dependencies: re-run the effect whenever `value` or `delay` changes.

    // Return the debounced value (debounceVal should be declared in the state).
    return debounceVal // Ensure `debounceVal` is defined as a state variable somewhere.
  }

  const handleRefundSubmitMutation = useMutation(
    async (payload) => {
      return await purchaseOrderService.addRefund(payload)
    },
    {
      onSuccess: () => {
        setLoading(false)
        setRefundDetails({
          marketplace_name: '',
          date_of_refund: '',
          order_date: '',
          order_id: '',
          return_fee: '',
          country_of_dispatch: '',
          shipped_country: '',
          transaction_type: '',
          customer_support: contextObj.userData.user?.id,
          refund_transaction_type: '',
          status: '',
          sku: '',
          currency: ''
        })
        contextObj.showToast('Refund added successfully')
      },
      onError: (error) => {
        contextObj.showToast(error?.data?.message)
        setLoading(false)
      }
    }
  )

  // useQuery hook to fetch shop data using 'getShop' as the query key
  useQuery(
    ['getShop'], // Unique key for caching and managing the query
    async () => {
      // Asynchronous function to fetch shop data from purchaseOrderService
      const response = await purchaseOrderService.getShopData()
      return response.data // Return the data from the response
    },
    {
      // Query options
      refetchOnWindowFocus: false, // Prevents refetching data when window is focused
      onSuccess: (response) => {
        // Callback for when the query is successful
        response = sortAlphabeticOrder(response, 'shop') // Sort the response alphabetically by 'shop'
        const sortRes = response.map((item) => item.shop) // Extract the 'shop' field from the sorted response
        setShop(sortRes) // Update the state with the sorted shop data
      },
      onError: (error) => {
        // Callback for when an error occurs
        console.error(error?.data) // Log the error data to the console
        contextObj.showToast(error?.data?.message) // Display an error message to the user via a toast
      }
    }
  )

  // useQuery hook to fetch warehouse data using 'getWarehouse' as the query key
  useQuery(
    ['getWarehouse'], // Unique key for caching and managing the query
    async () => {
      // Asynchronous function to fetch warehouse data from purchaseOrderService
      const response = await purchaseOrderService.getWarehouseData()
      return response.data // Return the data from the response
    },
    {
      // Query options
      refetchOnWindowFocus: false, // Prevents refetching data when window is focused
      onSuccess: (response) => {
        // Callback for when the query is successful
        setWareHouse(response) // Update the state with the fetched warehouse data
      },
      onError: (error) => {
        // Callback for when an error occurs
        console.error(error?.data) // Log the error data to the console
        contextObj.showToast(error?.data?.message) // Display an error message to the user via a toast
      }
    }
  )

  const handleChange = (name, value) => {
    setRefundDetails({ ...refundDetails, [name]: value })
  }
  const submitRefundData = async (event) => {
    event.preventDefault()
    if (validateForm()) {
      setLoading(true)
      await handleRefundSubmitMutation.mutateAsync(refundDetails)
    }
  }

  const handleInputChange = (field, value) => {
    setRefundDetails({ ...refundDetails, [field]: value })
    if (value) {
      setErrors({ ...errors, [field]: '' })
    }
  }

  const validateForm = () => {
    let valid = true
    let errors = {}

    if (!refundDetails.marketplace_name) {
      errors.marketplace_name = 'Market Place Name is required'
      valid = false
    }
    if (!refundDetails.order_id) {
      errors.order_id = 'Order Number is required'
      valid = false
    }
    if (!refundDetails.shipped_country) {
      errors.shipped_country = 'Shipped Country is required'
      valid = false
    }
    if (!refundDetails.order_date) {
      errors.order_date = 'Order Date is required'
      valid = false
    }
    if (!refundDetails.date_of_refund) {
      errors.date_of_refund = 'Date Of Refund is required'
      valid = false
    }
    if (!refundDetails.return_fee) {
      errors.return_fee = 'Refund Amount is required'
      valid = false
    }
    if (!refundDetails.country_of_dispatch) {
      errors.country_of_dispatch = 'Dispatched Country is required'
      valid = false
    }
    if (!refundDetails.transaction_type) {
      errors.transaction_type = 'Transaction Type is required'
      valid = false
    }
    if (!refundDetails.status) {
      errors.status = 'Status is required'
      valid = false
    }
    if (!refundDetails.sku) {
      errors.sku = 'SKU is required'
      valid = false
    }
    if (!refundDetails.currency) {
      errors.sku = 'currency is required'
      valid = false
    }

    setErrors(errors)
    return valid
  }

  // Using the custom hook `useDebounce` to debounce the `refundDetails.order_id` value.
  // The hook returns a debounced value that updates only after the specified delay ( 2 seconds).
  const debounceValue = useDebounce(refundDetails?.order_id, 2000)

  useQuery(
    // The query key, which uniquely identifies this query (useful for caching).
    // The query key consists of 'metadataAndProduct', refundDetails?.order_id, and debounceValue.
    ['getSalesOrderData', refundDetails?.order_id, debounceValue],
    async () => {
      // Make an API call to fetch sales order data based on the refundDetails.order_id
      const response = await purchaseOrderService.getSalesOrderData(refundDetails?.order_id)
      //Reset The value of debouce to stop run usequery on change of each value
      setDebounceValue('')
      return response?.data
    },
    {
      // Enable the query only if debounceValue exists (i.e., not an empty string or undefined).
      enabled: !!debounceValue,

      // Number of times to retry the query on failure. Here, it's set to retry based on debounceValue.
      retry: debounceValue,

      // Prevents refetching the query when the window regains focus.
      refetchOnWindowFocus: false,
      // Callback to execute if the query succeeds.
      onSuccess: (orderDetails) => {
        if (orderDetails && orderDetails.length) {
          contextObj.showToast('Order Details fetched successfullu', true) // Display message to the user via a toast
          // Filter the warehouse data to find the country where the warehouse matches the order's warehouse.
          const warehouseCountry = warehouse.filter(
            (filterValue) => filterValue.warehouse == orderDetails[0]?.warehouse
          )

          // Map through the orderDetails and extract the product names (SKU).
          const warehouseSku = orderDetails.map((filterValue) => filterValue.product?.product_name)

          // Update the refundDetails state with relevant information from orderDetails and warehouseCountry.
          setRefundDetails({
            ...refundDetails, // Spread the existing refund details to preserve other values
            marketplace_name: orderDetails[0]?.shop, // Set the marketplace/shop name from the first order detail
            order_date: orderDetails[0]?.order_date.slice(0, 10), // Extract and format the order date (YYYY-MM-DD)
            country_of_dispatch: warehouseCountry[0]?.country, // Set the dispatch country from the filtered warehouse data
            shipped_country: orderDetails[0]?.shipment_country, // Set the country where the product was shipped
            sku: warehouseSku.toString() // Convert the array of product names (SKUs) to a string
          })
        } else {
          contextObj.showToast('No Order Details Found for ' + refundDetails?.order_id) // Display message to the user via a toast
        }
      },

      onError: (error) => {
        // Callback for when an error occursxc
        console.error(error)
      }
    }
  )

  return {
    refundDetails,
    submitRefundData,
    handleChange,
    loading,
    errors,
    handleInputChange,
    shop
  }
}
