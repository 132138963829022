import { UserContext } from 'context/AuthContext/UserContext'
import { useContext } from 'react'
import { useState, useEffect } from 'react'
import purchaseOrderService from 'shared/services/purchase-order-service'
//import WarehouseServices from 'shared/services/warehouseServices'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { sortAlphabeticOrder } from 'shared/util/helper'

export const useProjectHooks = () => {
  const navigate = useNavigate()
  const contextObj = useContext(UserContext)
  const { setLoading, showToast } = useContext(UserContext)
  const userType = contextObj?.userData?.user?.pomUserType === 1 ? true : false
  const { projectId } = useParams()
  const [values, setValues] = useState({
    userId: '',
    companyId: '',
    purchaseOrder: [{}]
  })
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [tempData, setTempData] = useState([])
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchKey, setSearchKey] = useState('')
  const [selected, setSelected] = useState([])
  const [purchaseOrderDetails, setPurchaeseOrderDetails] = useState([])
  const [sortOn, setSortOn] = useState('arrivalDate')
  const [isEmpty, setIsEmpty] = useState(false)
  const [showReviewList, setShowReviewList] = useState(false)
  const [sortOrder, setSortOrder] = useState(1)
  const [draftBtn, setDraftBtn] = useState(false)
  const [isload, setLoad] = useState(true)
  const [validated, setValidated] = useState(false)
  const [skuValue, setSkuValue] = useState([])
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)
  const [show, setShow] = useState('')
  const [arrivalWarehouse, setArrivalWarehouse] = useState([])
  function getDate(date) {
    if (date) return moment(date).format('YYYY-MM-DD')
    else return null
  }

  useEffect(() => {
    fetchPurchaseOrders()
  }, [])

  const fetchPurchaseOrders = () => {
    try {
      setIsLoading(true)
      purchaseOrderService.getPurchaseOrder(contextObj.userData.user.companyId).then(
        (response) => {
          const arrPurchaseOrder = response.data?.filter(
            (catalogue) =>
              catalogue.status === 'Confirmed' ||
              catalogue.status === 'Shipped' ||
              catalogue.status === 'Partially Shipped'
          )
          setPurchaseOrders(arrPurchaseOrder)
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
          setError(error.data)
        }
      )
    } catch (err) {
      setError('Some Error Occured, Please Try Again')
      setIsLoading(false)
    }
  }
  // Sorting need to Implement on backend
  function dynamicSort(property) {
    if (property[0] === '-') {
      setSortOrder(-1)
      property = property.substr(1)
    }
    return function (a, b) {
      const aValue = a[property]
      const bValue = b[property]
      if (aValue == null && bValue == null) {
        return 0
      } else if (aValue == null) {
        return 1 * sortOrder
      } else if (bValue == null) {
        return -1 * sortOrder
      }
      const result = aValue < bValue ? -1 : aValue > bValue ? 1 : 0
      return result * sortOrder
    }
  }
  const setFinalCatalogueData = (ar, val) => {
    if (val == 2) {
      const ans = ar.filter((item) => item?.status.props?.status == 2)
      return ans
    } else {
      const ans = ar.filter((item) => item.status.props?.status != 2)
      return ans
    }
  }
  function createData(catalogue, supplier, shipTo, poDate, poDueDate, shipVia, status, checkbox) {
    return {
      catalogue,
      supplier,
      shipTo,
      poDate,
      poDueDate,
      shipVia,
      status,
      checkbox
    }
  }
  const handleChange = (value) => (event) => {
    if (event.target.value !== '') {
      setValues({ ...values, [value]: event.target.value })
      setSkuValue([])
    } else {
      setValues({ ...values, [value]: event.target.value })
    }
  }
  useEffect(() => {
    setShow(values.ArrivalWarehouse)
  }, [values])
  const getFormData = async (projectId) => {
    const response = await purchaseOrderService.getProjectOnProjectId(projectId)
    const data = response.data
    const startdate = getDate(data.startDate)
    const loaddate = getDate(data.loadDate)
    const cutoffdate = getDate(data.cutOffDate)
    const departuredate = getDate(data.departureDate)
    const arrivaldate = getDate(data.arrivalDate)
    const deliverydate = getDate(data.deliveryDate)
    const containerreturntoportdate = data?.containerReturntoPortDate ? getDate(data.containerReturntoPortDate) : null
    const bookingnumber = data.bookingNumber
    const container = data.container
    const vesselname = data.vesselName
    const freightline = data.freightLine
    const departureport = data.departurePort
    const arrivalwarehouse = data.arrivalWarehouse
    const arrivalport = data.arrivalPort
    // const po = data.purchaseOrder
    const updatedResponse = {
      projectId: data.projectId,
      StartDate: startdate,
      loadDate: loaddate,
      CutOffDate: cutoffdate,
      DepartureDate: departuredate,
      ArrivalDate: arrivaldate,
      DeliveryDate: deliverydate,
      ContainerReturntoPortDate: containerreturntoportdate,
      BookingNumber: bookingnumber,
      Container: container,
      VesselName: vesselname,
      FreightLine: freightline,
      DeparturePort: departureport,
      ArrivalWarehouse: arrivalwarehouse,
      ArrivalPort: arrivalport,
      // Exclude original properties to avoid duplicates
      ...Object.keys(data)
        .filter(
          (key) =>
            ![
              'projectId',
              'bookingNumber',
              'container',
              'vesselName',
              'freightLine',
              'departurePort',
              'arrivalWarehouse',
              'arrivalPort',
              'startDate',
              'loadDate',
              'cutOffDate',
              'departureDate',
              'arrivalDate',
              'containerReturntoPortDate',
              'deliveryDate'
            ].includes(key)
        )
        .reduce((obj, key) => {
          obj[key] = data[key]
          return obj
        }, {})
    }
    return updatedResponse
  }
  const {} = useQuery(['formData', projectId], () => getFormData(projectId), {
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    onError: () => {
      setError('Seems like our server is down')
    },
    onSuccess: (data) => {
      setTempData(data)
      setValues(data)
      setShow(data.ArrivalWarehouse)
      setPurchaeseOrderDetails(data.purchaseOrder)
      setSelected(data.purchaseOrder.map((poData) => poData.poId))
    }
  })
  const { data: warehouses } = useQuery(
    ['getWarehouse'],
    async () => {
      const response = await purchaseOrderService.getWarehouseData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'warehouse')
        const sortRes = response.map((item) => item.warehouse)
        setArrivalWarehouse(sortRes)
      },
      onError: (error) => {
        setError(error.response?.data?.message || 'Some error occurred')
      }
    }
  )  
  const handlePoSubmitMutation = useMutation(
    async ({ ProjectValues }) => {
      return await purchaseOrderService.addProject(ProjectValues)
    },
    {
      onSuccess: (response) => {
        setSuccess(true)
        setLoading(false)
        setTimeout(() => {
          navigate(`/projectsdetails/${response?.data?.data}`)
        }, 1500)
      },
      onError: (error) => {
        setLoading(false)
        setSuccess(false)
        contextObj.showToast(error.response.data.error_description || 'Some error occurred')
        setError(error || 'Some error occurred')
        console.error('Error adding project:', error)
        throw error
      }
    }
  )
  const fetchPoId = async (Ids) => {
    let POArr = []
    const responses = await purchaseOrderService.getPurchaseOrdersOnIds(Ids)
    responses.data.forEach((ponumber) => {
      POArr.push(ponumber.poNumber)
    })
    await fetchPoDeliveryId(POArr)
    const dataArray = await responses.data
    if (dataArray && Array.isArray(dataArray) && !projectId) {
      for (const data of dataArray) {
        let data_to_save = {
          user_id: userType ? data?.supplier : data?.userId,
          companyId: userType ? data?.supplierCompany : data?.companyId,
          redirect_uri: data.poNumber,
          type: 'PURCHASE_ORDER',
          title: 'New Notification Received',
          message: `Your POV: ${data.poNumber} Is Scheduled to Pickup at  ${moment(values.loadDate).format(
            'DD-MMM-YYYY'
          )}`,
          created_at: +new Date()
        }
        try {
          await purchaseOrderService.createNotification(data_to_save)
        } catch (error) {
          console.error('Error creating notification for:', data_to_save, error)
        }
      }
    }
  }
  async function formatOdooPayload(obj) {
    const allProductIds = await obj?.purchaseOrder?.map((currentPurchaseOrder) =>
      currentPurchaseOrder?.products.map((product) => product.productId)
    )
    const productDetails = await purchaseOrderService.getProductsOnIds(allProductIds)
    const formattedPurchaseOrders = await obj.purchaseOrder.reduce((acc, currentPurchaseOrder) => {
      const products = currentPurchaseOrder?.products?.map((product) => {
        const productDetail = productDetails?.data?.find((item) => item?.id === product?.productId)
        return {
          purchase_order: currentPurchaseOrder?.poNumber,
          vendor_id: currentPurchaseOrder?.supplierName,
          source_location: `TWS/${currentPurchaseOrder?.supplierName}'s Location`,
          product_id: productDetail ? productDetail?.sku : '',
          demanded_quantity: Number(product?.validatedQty?.qty),
          status: 'NEW'
        }
      })
      products.forEach((product) => {
        acc.push(product)
      })
      return acc
    }, [])
    if (obj.ArrivalWarehouse) {
      const matchingWarehouse = warehouses.find((warehouse) => warehouse.warehouse === obj.ArrivalWarehouse)
      if (matchingWarehouse) {
        obj.ArrivalWarehouse = matchingWarehouse.code
      }
    }
    return {
      ...obj,
      purchaseOrder: formattedPurchaseOrders
    }
  }
  const handlePoSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
      return
    }
    if (!values.projectId) return setValidated(true)
    if (!values.BookingNumber) return setValidated(true)
    if (!values.Container) return setValidated(true)
    if (!values.VesselName) return setValidated(true)
    if (!values.FreightLine) return setValidated(true)
    if (!values.StartDate) return setValidated(true)
    if (!values.projectId) return setValidated(true)
    if (!values.DeliveryDate) return setValidated(true)
    try {
      let arrIds = []
      const purchaseOrders = selected.map((poId) => {
        arrIds.push(poId)
        return { poId }
      })
      const ProjectValues = {
        ...values,
        userId: contextObj.userData.user.id,
        companyId: contextObj.userData.user.companyId,
        projectStage: 'Waiting',
        purchaseOrder: purchaseOrders
      }
      if (!ProjectValues.purchaseOrder.length) showToast('Select Purchase Order')
      if (!ProjectValues.purchaseOrder.length) return setErrorMessage(true)
      setLoading(true)
      setValidated(true)
      setIsLoading(!isLoading)
      setLoad(false)
      let Payload = {
        ...values,
        purchaseOrder: purchaseOrderDetails
      }
      const data = await formatOdooPayload(Payload)
      let Oddopayload = {
        name: data?.Container,
        destination_location: data?.ArrivalWarehouse,
        import_number: data?.projectId,
        ship_date: data?.DepartureDate,
        delivery_date: data?.DeliveryDate,
        items: data?.purchaseOrder
      }
      const apiRes = await purchaseOrderService.addProjectOdoo(Oddopayload)
      console.log(apiRes)
      setErrorMessage(false)
      await handlePoSubmitMutation.mutateAsync({ ProjectValues, fetchPoId, arrIds })
      await fetchPoId(arrIds)
      setSuccess(true)
      setValidated(false)
      setValues({ userId: '', companyId: '', purchaseOrder: [{}] })
    } catch (error) {
      showToast(error?.response?.data?.message)
      setLoading(false)
      console.error('Error handling PoSubmit:', error)
    } finally {
      setValidated(false)
      setIsLoading(false)
      setLoading(false)
    }
  }
  const fetchPoDeliveryId = async (ponumber) => {
    await purchaseOrderService.getPurchaseDeliveryOnPoNumber(ponumber).then(async (response) => {
      let deliveryValues = {
        purchaseOrder: response.data,
        userId: contextObj.userData.user.id,
        companyId: contextObj.userData.user.companyId,
        deliveryId: values.projectId + 'DL' + '-' + '1',
        projectId: values.projectId,
        date: values.StartDate,
        status: 'Waiting'
      }
      await createProjectDelivery(deliveryValues)
    })
  }
  const useCreateProjectDeliveryMutation = useMutation(
    async (deliveryValues) => {
      const res = await purchaseOrderService.createDelivery(deliveryValues)
      return res
    },
    {
      onSuccess: () => {
        setSuccess(true)
        setIsLoading(false)
      },
      onError: (error) => {
        setSuccess(false)
        setError(error)
        setIsLoading(false)
      }
    }
  )
  const createProjectDelivery = async (deliveryValues) => {
    try {
      await useCreateProjectDeliveryMutation.mutateAsync(deliveryValues)
    } catch (error) {
      console.error('Error creating project delivery:', error)
    }
  }
  const editProjectMutation = useMutation(
    (finalPayload) => purchaseOrderService.updateProjectOnProjectId(finalPayload),
    {
      onSuccess: (response) => {
        setSuccess(true)
        setIsLoading(false)
        setLoading(false)
        setTimeout(() => {
          navigate(`/projectsdetails/${response?.data?.projectId}`)
        }, 1500)
      },
      onError: (error) => {
        setSuccess(false)
        console.error(error)
        setError(error)
        setIsLoading(false)
        setLoading(false)
      }
    }
  )
  const EditProject = async (event) => {
    setIsLoading(!isLoading)
    setLoad(false)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    let arrIds = []
    const purchaseOrders = selected.map((poId) => {
      arrIds.push(poId)
      return {
        poId
      }
    })
    const ProjectValues = {
      ...values,
      userId: contextObj.userData.user.id,
      companyId: contextObj.userData.user.companyId,
      purchaseOrder: purchaseOrders
    }
    const changes = {}
    Object.keys(ProjectValues).forEach((key) => {
      if (key === 'purchaseOrder') {
        const oldPoIds = (tempData.purchaseOrder || []).map((po) => po.poId)
        const newPoIds = (ProjectValues.purchaseOrder || []).map((po) => po.poId)
        if (JSON.stringify(oldPoIds) !== JSON.stringify(newPoIds)) {
          changes[key] = {
            oldValue: oldPoIds,
            newValue: newPoIds
          }
        }
      } else if (tempData[key] !== ProjectValues[key]) {
        changes[key] = {
          oldValue: tempData[key],
          newValue: ProjectValues[key]
        }
      }
    })
    const finalPayload = {
      ...ProjectValues,
      changes
    }
    if (purchaseOrders.length > 0) {
      setLoading(true)
      setErrorMessage(false)
      try {
        let res = await editProjectMutation.mutateAsync(finalPayload)
        if (res.data?.projectStage === 'Waiting' || res.data?.projectStage === 'Loading') {
          await fetchPoId(arrIds)
        }
        setValidated(false)
        setValues({
          userId: '',
          companyId: '',
          purchaseOrder: [{}]
        })
      } catch (error) {
        setLoading(false)
        setErrorMessage(true)
        console.error(error)
      }
    } else {
      setErrorMessage(true)
    }
  }

  return {
    values,
    purchaseOrders,
    setPurchaseOrders,
    rows,
    setRows,
    isLoading,
    setIsLoading,
    searchKey,
    setSearchKey,
    selected,
    setSelected,
    setPurchaeseOrderDetails,
    sortOn,
    setSortOn,
    isEmpty,
    setIsEmpty,
    showReviewList,
    setShowReviewList,
    sortOrder,
    setSortOrder,
    draftBtn,
    setDraftBtn,
    handleChange,
    error,
    handlePoSubmit,
    validated,
    // editProjectPath,
    EditProject,
    isLoading,
    isload,
    success,
    skuValue,
    setSkuValue,
    dynamicSort,
    setFinalCatalogueData,
    createData,
    errorMessage,
    show,
    setShow,
    //warehouseName,
    fetchPoDeliveryId,
    arrivalWarehouse
  }
}
